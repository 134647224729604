// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'common',
  defaultJurisdiction: 'mga',
  CURRENT_ENV,
  type: 'pnp',
  indexContainer: 'IndexContainerPnp',
  skinId: CURRENT_ENV === 'dev' ? 74 : 64,
  defaultLocale: 'fi-FI',
  MERCHANT_ID: CURRENT_ENV === 'dev' ? 100014986 : 100014025,
  content: {
    space: 'PrDNWYkUl7D2',
    host: 'https://delivery2.objectic.io',
    access_token: 'Y9jvPc7QUokjLbn3uE9C4K8vaOb2XrCk4R7EWVkJ',
    preview_host: 'https://preview.objectic.io',
    preview_access_token: 'Y9jvPc7QUokjLbn3uE9C4K8vaOb2XrCk4R7EWVkJ',
    environment: CURRENT_ENV === 'dev' ? 'stage' : 'master',
  },
  games: {
    space: 'jIWXpQOLRVBo',
    host: 'https://delivery2.objectic.io',
    access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
    preview_host: 'https://preview.objectic.io',
    preview_access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
    environment: CURRENT_ENV === 'prod' ? 'master' : 'master',
    gameIdKey: CURRENT_ENV === 'prod' ? 'tg-pz/senshiro/supernopea' : 'tg-pz-stage/senshiro/supernopea',
  },
  axiosCache: {
    duration: 60, // 1 minute
    whitelist: ['delivery2.objectic.io', '/event-feed/jackpots'],
  },
  jurisdictions: [
    {
      key: 'mga',
      name: 'supernopea.com',
      domain: CURRENT_ENV === 'dev' ? null : 'www.supernopea.com',
    },
    {
      key: 'sga',
      name: 'supernopea.com (se)',
      domain: CURRENT_ENV === 'dev' ? null : 'www.supernopea.com',
    },
  ],
  tgplay: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://play-togethergaming-new-config-stage.azurewebsites.net'
        : 'https://play.supernopea.com',
    skin: 'supernopea_pz',
    standalone: true,
  },
  aleacc: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://api-stage-supernopea.codebet.dev'
        : 'https://api.supernopea.com',
  },
  devcode: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://test-bo.paymentiq.io/paymentiq/api'
        : 'https://api.paymentiq.io/paymentiq/api',
    oauth_host:
      CURRENT_ENV === 'dev'
        ? 'https://test-api.paymentiq.io/paymentiq/oauth'
        : 'https://backoffice.paymentiq.io/paymentiq/oauth',
    oauth_provider: 'trustly',
    oauth_clientid:
      CURRENT_ENV === 'dev'
        ? 'e84c9e41c16e4b04855307e3fe8f731c'
        : 'd570ac48801542f4b38fdfe8d00f63b2',
    oauth_redirecturl:
      CURRENT_ENV === 'dev'
        ? 'https://stage-supernopea.codebet.dev/nemid.html'
        : 'https://www.supernopea.com/nemid.html',

    oauth_ui_friendly: 'bethard-1',
  },
  metric: {
    host:
      CURRENT_ENV === 'dev'
        ? 'https://supernopea.superlivepreprod.com'
        : 'https://supernopea.betpump.com',
  },
  authenticgaming: {
    host:
      CURRENT_ENV === 'dev'
        ? 'http://game.authenticstage.live/game/loader.aspx?'
        : 'https://game.itsreal.live//game/loader.aspx?',
    operatorId: 38,
  },
  quickDeposit: {
    enabled: false,
    host: 'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/tg-quickdeposit.bundle.js',
    successUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/success.html',
    failureUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/failure.html',
    cancelUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/cancel.html',
  },

  chat: {
    provider: 'zendesk',
    host: 'https://static.zdassets.com/ekr/snippet.js?key=ba2367df-287b-4c45-b31b-16f45be4adbf',
    secret: '9DC9F1C05BCE1B215BB934F9A2256FDA5DDBE0FF22DDE0FD8348098AD04A3CC1',
    departments: [
      'SuperNopea English Support',
      'SuperNopea Deutscher Kundenservice',
      'SuperNopea Asiakaspalvelu Suomeksi',
      'SuperNopea Norsk Kundeservice',
      'SuperNopea Soporte en Español',
      'SuperNopea Suporte Portugues',
    ],
    tags: ['supernopea'],
    colors: {
      theme: '#5fa9c1',
      launcher: '#5fa9c1',
      launcherText: '#ffffff',
      button: '#5fa9c1',
    },
  },

  imProvider: 'fasttrack',

  fastTrack: {
    brand: 'mozebra',
    config: {
      integrationVersion: 1.1,
      autoInit: true,
      inbox: {
        enable: true,
        badgeId: '#ft-crm-inbox-badge',
        navBarLogo: 'https://via.placeholder.com/200x200',
        // contactEmail: "support@example.com",
        supportLink: 'mailto:daniel@fasttrack-solutions.com',
        supportLinkText: 'customer support »',
      },
    },
    script:
      CURRENT_ENV === 'dev'
        ? 'https://crm-lib-staging.fasttrack-solutions.com/loader/fasttrack-crm.js'
        : 'https://crm-lib.fasttrack-solutions.com/loader/fasttrack-crm.js',
  },

  /**
   * Visibility configuration for user registration.
   * @description
   * Current available elements for the array: ['email', 'phone']
   */
  userFields: ['email', 'phone'],

  /**
   * How the index page features should be displayed
   * @description
   * Current available options are 'row', 'slider', 'textSlider' and 'iconSlider'
   * Default :
   * desktop: 'row',
   * mobile: 'slider'
   */
  indexFeatures: {
    desktop: 'row',
    mobile: 'slider',
  },

  /**
   * Custom logo url
   */
  logoUrl: '/skin/images/logo.svg',

  /**
   * If the sign in/fetch balance button should be displayed in header or base page.
   * @description
   * Default is true which means it should be in header.
   * showFetchBalanceIndexHeader: true
   */
  showFetchBalanceIndexHeader: true,

  showBonusSelector: true,
  globalNavContainer: false,

  products: ['casino', 'payments'],

  hideOnScroll: {
    footer: false,
  },

  /**
   * Show sticky footer on desktop
   */
  showStickyFooter: false,

  /**
   * Show sticky footer sign up CTA
   */
  stickyFooterButton: false,

  /**
   * Show sticky footer on the index page, will also hide the hamburger
   */
  stickyFooterOnIndex: true,

  /*
   * Choose if support button in header/dashboard should navigate to page or open support widget
   */
  openSupportWidget: {
    header: false,
    sidebar: true,
  },

  /**
   * Casino options
   * @property layout Game grid layout (slider, grid)
   * @property searchStyle Show casino categories (categories, noCategories)
   */
  casino: {
    layout: 'slider',
    casinoCategoryMenuStyle: 'categories',
    liveCasinoData: true,
  },

  redirectCasino: true,
  preGameView: {
    signedIn: true,
    signedOut: true,
  },

  /**
   * Options for communication consent checkbox on details step.
   */
  communicationConsent: {
    enabled: true,
    values: [
      'BonusEmails',
      'BonusSMSs',
      'NewsEmails',
      'NewsSMSs',
      'Phone',
      'Casino',
      'Sportsbook',
    ],
  },

  visitedCookieName: 'tg-page-visited',
  visitedCookieMaxAge: 365 * 24 * 60 * 60 * 1000,
  gameHistoryName: 'tg-game-history',
  btagCookie: 'tg-btag',
  defaultCurrency: 'EUR',

  GTM_ID: 'GTM-WMH7C3N',

  redirects: [
    {
      source: /^\/unsub/,
      target: () => '/fetch-balance?ref_url=dashboard/subscriptions',
    },
    {
      source: '/deposit',
      target: () => '/start-playing',
    },
    {
      source: '/withdraw',
      target: () => '/#withdraw',
    },
    {
      source: /^\/(sign-in|login)$/,
      target: () => '/fetch-balance',
    },
    {
      source: '/sign-up',
      target: () => '/#sign-up',
    },
    {
      source: '/dashboard',
      target: () => '/#dashboard',
    },
    {
      source: /^\/(dashboard\/activate|activate)$/,
      target: () => '/#dashboard/activate',
    },
    {
      source: '/dashboard/responsible-gaming',
      target: () => '/#dashboard/responsible-gaming',
    },
    {
      source: '/dashboard/subscriptions',
      target: () => '/#dashboard/subscriptions',
    },
    {
      source: '/dashboard/verify',
      target: () => '/#dashboard/verify',
    },
    {
      source: '/dashboard/bonus',
      target: () => '/#dashboard/bonus',
    },
    {
      source: '/dashboard/history',
      target: () => '/#dashboard/history',
    },
    {
      source: '/dashboard/messages',
      target: () => '/#dashboard/messages',
    },
    {
      source: '/dashboard/edit-profile',
      target: () => '/#dashboard/edit-profile',
    },
    {
      source: '/dashboard/change-password',
      target: () => '/#dashboard/change-password',
    },
    {
      source: '/sbtech/sbtech.js',
      target: () => '/sbtech.js',
    },
    {
      source: '/sports',
      target: () => '/casino',
    },
  ],
  /**
   * @param {Request} [req] Provide the request object if this function is being used on the server
   * @returns {(string|boolean)}
   */
  cookieDomain: (req) => {
    if (!__CLIENT__ && !req) return false;
    return (__CLIENT__ ? window.location.href : req.get('host')).includes(
      'supernopea.com'
    )
      ? '.supernopea.com'
      : false;
  },
});
